import { FC, useEffect, useState } from "react"
import NavBar from "../../components/NavBar"
import mainLogo from './../../img/logo_main.png';
import DatePicker from "react-datepicker";
import ListOnly from "../../components/ListOnly";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { resSub } from "../../redux/slices/sheetsSlice/sheetsSlice";
import LatinaController from "../../API/LatinaController";
import { UserService } from "../../services/user.service";

import api from './../../API'
import { resLogged, setLoggedIn } from "../../redux/slices/userSlice";
import { SubscriptType } from "../../constants/subscriptType";

export const AddSubscripV2: FC = () => {



  let navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());
  const [sendDisable, setSendDisable] = useState(false);
  const [subscriptType, setSubscriptType] = useState('абн');

  const sub = useAppSelector((state) => state.sheets.sub)
  const dispatch = useAppDispatch()

  const token = useAppSelector((state) => state.usersR.token)
  const userName = useAppSelector((state) => state.usersR.user.username)
  const userFio = useAppSelector((state) => state.usersR.user.fio)
  const userId = useAppSelector((state) => state.usersR.user.id)


  useEffect(()=> {

      auth()

	}, [])

  const auth = async () => {
    try {
      const profile = await UserService.getProfile()
      // const me = await LatinaController.me()
      // console.log(me)
      // api.getUserData()
      // .then((res: any) => {
      // })
      // .catch(err => {
      //   localStorage.removeItem('token')
      //   dispatch(setLoggedIn(false));
      //   dispatch(resLogged());
      // })
    } catch (e) {
      localStorage.removeItem('token')
      dispatch(setLoggedIn(false));
      dispatch(resLogged());
      navigate('/');
    }
  }

  const resetStudents = () => {
    dispatch(resSub())
  }

  const onStudents = () => {
    navigate('/teacher', {state: {url: `${process.env.REACT_APP_DOM}/api/student/`, tagSearch: 'subscrip', title: 'Ученики'}} );
  }

  const onSend = async () => {
    // const th = document.querySelector('.sheets__send');
    // console.log(th)
    // if (th) {
      // th.classList.add('sheets__send_disable')
      setSendDisable(true)
    // }

    // console.log('sub')
    // const inputSubNum = Number((document.querySelector('#inputSubNum') as HTMLInputElement).value)
    const inputPrice =  Number((document.querySelector('#inputPrice') as HTMLInputElement).value)
    const inputBalance = Number((document.querySelector('#inputBalance') as HTMLInputElement).value)
    const inputType = (document.querySelector('#inputType') as HTMLInputElement).value
    const inputInfo = (document.querySelector('#inputInfo') as HTMLInputElement).value
    // console.log(inputSubNum, inputPrice, inputBalance)

    const priceSub = process.env.REACT_APP_PRICE_SUB ? Number(process.env.REACT_APP_PRICE_SUB) : 0
    const priceFire = process.env.REACT_APP_PRICE_FIRE ? Number(process.env.REACT_APP_PRICE_FIRE) : 0
    const priceHoreo = process.env.REACT_APP_PRICE_HOREO ? Number(process.env.REACT_APP_PRICE_HOREO) : 0
    // if ((inputPrice == 1500 && inputBalance > 5) || ((inputPrice == 3000 || inputPrice == 2500) && inputBalance > 10) ) {
    if ((inputPrice >= 10000) || (inputBalance >= 100) || (( inputPrice === priceSub || inputPrice === priceFire || inputPrice === priceHoreo) && inputBalance > 10 ) ) {
      // alert('Ошибка: Значения некорректны. Большое кол-во занятий для указанной стоимости')
      alert('Ошибка: Значения некорректны - цена больше 10000 или кол-во занятий больше 100')
    } else {

      if (inputPrice < 5001 && inputPrice >= 1 && inputBalance >= 1 && inputBalance <= 10 && sub.length == 1) {

        // }

        let addSub

        try {
          if(subscriptType === 'абн') {
            addSub = await addSubscriptBase(inputPrice,inputBalance,inputType, inputInfo, sub[0], userName, userFio, userId)
          } else {
            addSub = await addSubscriptBase(inputPrice,inputBalance,inputType, inputInfo, sub[0], userName, userFio, userId, 'хорео')
          }

        } catch(e: any) {
          if(e && e.response && e.response.data && e.response.data.message) {
            alert(`Ошибка: ${e.response.data.message}`)
          } else {
            alert(`Ошибка: Абонемент не добавлен`)
          }
        }



        // addSubscriptLatina(sub[0], inputPrice, inputBalance, startDate.toISOString())


        // console.log('addSub',addSub)
  

        if(addSub) {

        


              
          const dataTestUser = {
            login: userName,
            message: addSub.message
          }
      
          const resUser = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTestUser)
          })
  
          if(userName !== process.env.REACT_APP_USER_TWO) {
  
          const dataTest = {
            login: process.env.REACT_APP_USER_TWO,
            message: addSub.message
          }
      
          const res = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTest)
          })
  
          }
  
          if(userName !== process.env.REACT_APP_USER_ONE) {
  
          const dataTest2 = {
            login: process.env.REACT_APP_USER_ONE,
            message: addSub.message
          }
      
          const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTest2)
          })
  
          }
  
              alert(`Ок: Абонемент добавлен\nНомер: ${addSub.item}`)
              navigate('/list');
        }




  
      }
      else {
        alert('Ошибка: Значения некорректны')
      }

    }
  
  }

//   const addSubscript = async (inputPrice: any,inputBalance: any, inputType: any, inputInfo: any) => {




//     fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/`, {
//       method: 'GET',
//       headers: {
//       accept: 'application/json',
//       'Content-Type': 'application/json; charset=UTF-8',
//       'Authorization': `Token ${token}`,
//       },
//       credentials: 'same-origin',
//     })
//     .then((res) => res.json())
//     .then(dataAllAbn => {

//       const number = dataAllAbn[0].id + 1
        
//       const dataPostFull = {
//         id_users: '1',
//         item: `А${number}`,
//         number: `${number}`,
//         balance: 0,
//         price: 0,
//         paymet: 'б/н',
//         prefix: 'SC',
//         pub_date: new Date(),
//         quantity: 10,
//         status: false,
//         info: null,
//       }

//       fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/`, {
//         method: 'POST',
//         headers: {
//         accept: 'application/json',
//         'Content-Type': 'application/json; charset=UTF-8',
//         'Authorization': `Token ${token}`,
//         },
//         credentials: 'same-origin',
//         body: JSON.stringify(dataPostFull)
//       })
//       .then((res) => res.json())
//       .then(dataAbn => {

//         // console.log('dataAbn', dataAbn)
        
//         const infoStu = JSON.parse(sub[0].info)

//         dataAbn.id_users = sub[0].number
//         dataAbn.status = true
//         dataAbn.info = JSON.stringify({info: infoStu.info})
//         dataAbn.price = inputPrice
//         dataAbn.quantity = inputBalance
//         dataAbn.balance = inputBalance
//         dataAbn.pub_date = startDate
//         dataAbn.number = dataAbn.id

//         if(inputPrice === 2500) {
//           dataAbn.item = `B${dataAbn.id}`
//         } else {
//           dataAbn.item = `A${dataAbn.id}`
//         }
                
//                 // }

//         fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/${dataAbn.id}/`, {
//           method: 'PATCH',
//           headers: {
//             accept: 'application/json',
//             'Content-Type': 'application/json; charset=UTF-8',
//             'Authorization': `Token ${token}`,
//           },
//           credentials: 'same-origin',
//           body: JSON.stringify(dataAbn)
//         })
//         .then((res) => res.json())
//         .then(dataSub => {

//           const userId = Number(dataSub.id_users)
          
//           fetch(`http://${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
//             method: 'GET',
//             headers: {
//               accept: 'application/json',
//               'Content-Type': 'application/json; charset=UTF-8',
//               'Authorization': `Token ${token}`,
//             },
//             credentials: 'same-origin',
//           })
//           .then((res) => res.json())
//           .then(dataStudent => {
//             // console.log(dataStudent)
//             // console.log(dataStudent.subscrip)

//             let arr = []
//             if(dataStudent.subscrip) {
//               arr =  dataStudent.subscrip.split(',')
//             }

//             arr.push(dataSub.item)

//             dataStudent.subscrip = arr.join (',')

//             fetch(`http://${process.env.REACT_APP_DOM}/api/student/${userId}/`, {
//               method: 'PATCH',
//               headers: {
//                 accept: 'application/json',
//                 'Content-Type': 'application/json; charset=UTF-8',
//                 'Authorization': `Token ${token}`,
//               },
//               credentials: 'same-origin',
//               body: JSON.stringify(dataStudent)
//             })


//             .then((res) => res.json())
//             .then(dataStudent2 => {

//               const infoStu = JSON.parse(sub[0].info)

              
//           const dataTestUser = {
//             login: userName,
//             message: `Абонемент добавлен\nНомер: ${dataSub.item}
// Дата: ${startDate.toISOString().slice(0,10)};
// Кол-во занятий: ${inputBalance};
// Цена: ${inputPrice};
// Выдал: ${userFio};
// Доп поле: ${inputInfo};
// Форма оплаты: ${inputType};
// Кому: ${infoStu.info};
// `
//           }
      
//           const resUser = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
//             method: 'POST',
//             headers: {
//               accept: 'application/json',
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(dataTestUser)
//           })

//           if(userName !== process.env.REACT_APP_USER_ONE) {

//           const dataTest = {
//             login: process.env.REACT_APP_USER_ONE,
//             message: `Абонемент добавлен\nНомер: ${dataSub.item}
// Дата: ${startDate.toISOString().slice(0,10)};
// Кол-во занятий: ${inputBalance};
// Цена: ${inputPrice};
// Выдал: ${userFio};
// Доп поле: ${inputInfo};
// Форма оплаты: ${inputType};
// Кому: ${infoStu.info};
// `
//           }
      
//           const res = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
//             method: 'POST',
//             headers: {
//               accept: 'application/json',
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(dataTest)
//           })

//           }

//           if(userName !== process.env.REACT_APP_USER_TWO) {

//           const dataTest2 = {
//             login: process.env.REACT_APP_USER_TWO,
//             message: `Абонемент добавлен\nНомер: ${dataSub.item}
// Дата: ${startDate.toISOString().slice(0,10)};
// Кол-во занятий: ${inputBalance};
// Цена: ${inputPrice};
// Выдал: ${userFio};
// Доп поле: ${inputInfo};
// Форма оплаты: ${inputType};
// Кому: ${infoStu.info};
// `
//           }
      
//           const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
//             method: 'POST',
//             headers: {
//               accept: 'application/json',
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(dataTest2)
//           })

//           }

//               alert(`Ок: Абонемент добавлен\nНомер: ${dataSub.item}`)
//               navigate('/list');
//             })
//             .catch(e => {
//               console.log('sub', e)
//             })
//           })
//           .catch(e => {
//             console.log('sub', e)
//           })
        
        
//         })
//         .catch(e => {
//           console.log('sub', e)
//         })


//       })
//       .catch(e => {
//         console.log('sub', e)
//       })

//     })
    
//   }

  const addSubscriptBase =  async (inputPrice: number,inputBalance: number,inputType: string, inputInfo: string, sub: any, userName: string, userFio: string, userId: string, spez?: string) => {

    const dataSend = {
      inputPrice,
      inputBalance,
      inputType,
      inputInfo,
      sub,
      userName,
      userFio,
      userId: Number(userId)
    }

    let resSubscription
    if(!spez) {
      resSubscription = await LatinaController.subscriptionCreate(dataSend)
    } else {
      resSubscription = await LatinaController.subscriptionCreateSpez(dataSend)
    }
    // console.log(resSubscription)

    return resSubscription
  }

  const addSubscriptLatina = async (sub: any, inputPrice: number, inputBalance: number, startDate: string) => {
    const dataSend = {
      numberStudent: sub.number,
      inputPrice,
      sub,
      inputBalance,
      startDate
    }
    const resSubscription = await LatinaController.addSubscript(dataSend)
  }

  let tempType: any

  const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {

		const value = event.target.value;

		switch(value) {
			case 'хорео':
        setSubscriptType('хорео')
				break
			default:
        setSubscriptType('абн')
				break
		}
	}

  return (
    <>
      <div className="List">
        <div className='lat-form'>
          <div className='lat-form__container lat-form__container_shadow'>
            <NavBar />
            <div className="lat-form__header header">
              <div className="header__left">

              </div>
              <div className="header__center">
                <img className="logo" src={mainLogo} alt='логотип'></img>
              </div>
              <div className="header__right">

              </div>
            </div>
            <div className="lat-form__sheets sheets">
              <div className="sheets__title">
                Новые абонементы <b>без номера</b>
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Дата
                </div>
                <div className="sheets__item-value">
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date as Date)} />
                </div>
              </div>
              {/* <div className="sheets__item">
                <div className="sheets__item-text">
                  Номер абонемента
                </div>
                <input id='inputSubNum' />
              </div> */}
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Цена
                </div>
                <input id='inputPrice' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Количество занятий
                </div>
                <input id='inputBalance' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Форма оплаты
                </div>
                <input id='inputType' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Доп поле
                </div>
                <input id='inputInfo' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Тип абонемента
                </div>
                <select id={`inputSubscriptType`} name={`inputSubscriptType`} value={tempType} onChange={onSelect} >
                  <option value={`абн`}>абн</option> 
                  { SubscriptType ? Object.keys(SubscriptType).map(key => {
                    return (<>
                      <option value={SubscriptType[key as keyof typeof SubscriptType]}>{SubscriptType[key as keyof typeof SubscriptType]}</option>
                    </>)
                  }) : (<>
                  <option value={`хорео`}>хорео</option>
                  </>)}
                </select>
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text">
                  Ученик
                </div>
                <button onClick={resetStudents}>
                  Очистить всех
                </button>
                <button onClick={onStudents} className={'sheets__btn'}>
                  Выбрать
                </button>
                  {/* <NavLink className={'sheets__btn'} to='/teacher' state={{url: "http://localhost:8000/api/direction/"}}>Добавить/обновить</NavLink> */}
              </div>
              <div className='sheets__list-stu'>
                <ListOnly
                students={sub}
                />
              </div>

              <div className="sheets__send">
                <button disabled={sendDisable} onClick={onSend}>
                  Отправить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

