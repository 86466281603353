export enum PayValue {
  '500б/н' = '500б/н',
  '500нал' = '500нал',
  '400б/н' = '400б/н',
  '400нал' = '400нал',
  '300б/н' = '300б/н',
  '300нал' = '300нал',
}
// export let PayValue: any

export const PAY_VALUE_DEFAULT = 400

